import { Dispatch } from "react";

import * as Sentry from "@sentry/nextjs";
import { NextRouter } from "next/router";

import { ConflictError } from "../../utils/errors/ConflictError";
import { RightsError } from "../../utils/errors/RightsError";
import { TooLargeError } from "../../utils/errors/TooLargeError";
import routes from "../../utils/routes";
import { NotificationsStore } from "./notifications.store";

export const errorHandlerFn =
    (
        notificationsStore,
        localeAs,
        type: "get" | "save" = "get",
        router?: NextRouter,
        setAccessDeny?: Dispatch<boolean>,
    ) =>
    (error) => {
        if (error instanceof RightsError) {
            handleError(
                notificationsStore,
                localeAs(`notifications.error.${type}`),
                localeAs("notifications.error.title"),
                localeAs("notifications.error.401"),
                undefined,
                error,
            );
            setAccessDeny && setAccessDeny(true);
            return router?.push(routes.main.forbidden);
        } else if (error instanceof ConflictError) {
            handleError(
                notificationsStore,
                localeAs(`notifications.error.${type}`),
                localeAs("notifications.error.title"),
                localeAs("notifications.error.409"),
                undefined,
                error,
            );
        } else if (error instanceof TooLargeError) {
            handleError(
                notificationsStore,
                localeAs(`notifications.error.${type}`),
                localeAs("notifications.error.title"),
                localeAs("notifications.error.413"),
                undefined,
                error,
            );
        } else {
            handleError(
                notificationsStore,
                localeAs(`notifications.error.${type}`),
                localeAs("notifications.error.title"),
                undefined,
                undefined,
                error,
            );
        }

        return Promise.resolve(false);
    };

export const handleError = (
    notificationStore: NotificationsStore,
    error: Error | string,
    title?: string,
    subtitle?: string,
    timeToLiveInMin?: number,
    nestedError?: Error,
) => {
    if (error instanceof Error) {
        notificationStore.addError(`${(error as Error).message}. ${subtitle ?? ""}`, title, timeToLiveInMin);
    } else {
        notificationStore.addError(`${error as string}. ${subtitle ?? ""}`, title, timeToLiveInMin);
    }

    if (nestedError) {
        Sentry.captureException(
            nestedError,
            notificationStore?.authStore
                ? {
                      user: {
                          id: notificationStore.authStore.userId?.toString(),
                          username: notificationStore.authStore.displayName,
                          email: notificationStore.authStore.userEmail,
                      },
                  }
                : undefined,
        );
    }
};

export const handleSuccessfully = (
    notificationStore: NotificationsStore,
    message: string,
    title: string,
    timeToLiveInMin?: number,
) => {
    notificationStore.addInfoNote(message, title, timeToLiveInMin);
};

export const handleWarning = (
    notificationStore: NotificationsStore,
    message: string,
    title: string,
    timeToLiveInMin?: number,
) => {
    notificationStore.addWarnNote(message, title, timeToLiveInMin);
};
